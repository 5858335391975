import React from "react";
import "./TableComponent.scss";
import { Route } from "react-router-dom";
import Leagues from "./../Leagues/Leagues";
import Fixtures from "../Fixtures/Fixtures";
import StadiumReviews from "../StadiumReviews";
import PubsReviews from "../PubsReviews";
import StadiumList from "./../StadiumList/StadiumList";
import EstimatedStadium from "./../EstimatedStadium/EstimatedStadium";
import StadiumDetail from "./../StadiumDetail/StadiumDetail";
import RivalriesList from "./../Rivalries";
import CreateRivalries from "./../Rivalries/CreateRivalries";
import Attractions from "../attractions";
import AttractionTypes from "../attractionTypes";
import AttractionReviews from "../attractionrReviews";
import Users from "../Users";
import TeamsPage from "../teams";

const TableComponent = () => (
	<>
		<Route
			exact
			path="/"
			component={Leagues}
		></Route>
		<Route
			exact
			path="/fixtures"
			component={Fixtures}
		></Route>
		<Route
			exact
			path="/stadiumList"
			component={StadiumList}
		></Route>
		<Route
			exact
			path="/estimatedStadium"
			component={EstimatedStadium}
		></Route>
		<Route
			exact
			path="/rivalries"
			component={RivalriesList}
		></Route>
		<Route
			exact
			path="/create-rivalries"
			component={CreateRivalries}
		></Route>
		<Route
			exact
			path="/update-rivalries/:id"
			component={CreateRivalries}
		></Route>
		<Route
			exact
			path="/attractions"
			component={Attractions}
		></Route>
		<Route
			exact
			path="/attractionTypes"
			component={AttractionTypes}
		/>
		<Route
			exact
			path="/attractionReviews"
			component={AttractionReviews}
		/>
		<Route
			exact
			path="/users"
			component={Users}
		/>
		<Route
			exact
			path="/teams"
			component={TeamsPage}
		/>
		<Route
			exact
			path="/stadiumList/stadiumDetail/:id"
			component={StadiumDetail}
		></Route>
	</>
);

export default TableComponent;
