import React, { useState, useEffect } from "react";
import { Modal, Form, Input, Button, Row, Col } from "antd";
import { useForm } from "antd/es/form/Form";
import PropTypes from "prop-types";

const EditEstimatedModal = ({ selectedEstimatedStadium, onClose, onSave }) => {
	const [form] = useForm();
	useEffect(() => {
		if (selectedEstimatedStadium) {
			form.setFieldsValue({
				id: selectedEstimatedStadium.id,
				name: selectedEstimatedStadium.name.stadium,
				city: selectedEstimatedStadium.city,
				lat: selectedEstimatedStadium.lat,
				lng: selectedEstimatedStadium.lng,
				venueId: selectedEstimatedStadium.venueId,
			});
		}
	}, [selectedEstimatedStadium, form]);

	const handleSave = () => {
		form.validateFields().then((values) => {
			onSave(values);
			onClose();
		});
	};

	return (
		<Modal
			title="Edit Stadium"
			visible={true}
			onCancel={onClose}
			footer={[
				<Button
					key="cancel"
					onClick={onClose}
				>
					Cancel
				</Button>,
				<Button
					key="save"
					type="primary"
					onClick={handleSave}
				>
					Update
				</Button>,
			]}
			width={500}
		>
			<Form
				form={form}
				layout="vertical"
			>
				<Row gutter={16}>
					<Col span={24}>
						<Form.Item
							name="venueId"
							label="venue Id"
						>
							<Input />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item
							name="name"
							label="Stadium Name"
						>
							<Input />
						</Form.Item>
					</Col>

					<Col span={24}>
						<Form.Item
							name="city"
							label="City"
						>
							<Input />
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							name="lat"
							label="Latitude"
						>
							<Input
								type="number"
								step="any"
							/>
						</Form.Item>
					</Col>

					<Col span={12}>
						<Form.Item
							name="lng"
							label="Longitude"
						>
							<Input
								type="number"
								step="any"
							/>
						</Form.Item>
					</Col>
				</Row>
			</Form>
		</Modal>
	);
};

EditEstimatedModal.propTypes = {
	user: PropTypes.array.isRequired,
	onClose: PropTypes.func.isRequired,
	onSave: PropTypes.func.isRequired,
};

export default EditEstimatedModal;
