/* eslint-disable */

import React, { useState, useEffect } from "react";
import { Table, Result, Button, Input, Select, DatePicker } from "antd";
import "./Leagues.scss";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../helper/api";
import EditLeagueModal from "./EditLeagueModal";
import useApiRequest from "../../helper/useApiHook";
import { showErrorToast, showSuccessToast } from "../../helper/toaster";
const { RangePicker } = DatePicker;

const { Search } = Input;
const { Option } = Select;

const Leagues = () => {
	const [modal, setModal] = useState(false);
	const [editLeague, setEditLeague] = useState([]);
	const [leagues, setLeagues] = useState([]);
	const [total, setTotal] = useState(10);
	const [page, setPage] = useState(1);
	const [selectedLeague, setSelectedLeague] = useState("");
	const [byName, setByName] = useState("");
	const [byCountry, setByCountry] = useState("");
	const [byPriority, setPriority] = useState(null);
	const [byActive, setByActive] = useState("");
	const [pastLeagues, setPastLeagues] = useState("future");
	const [leagueLoader, setLeagueLoader] = useState(true);
	const [leagueAttractionLoader, setLeagueAttractionLoader] = useState(false);
	const [leagueAttractionStadiumLoader, setLeagueAttractionStadiumLoader] =
		useState(false);
	const [error, setError] = useState(false);
	const [sortField, setSortField] = useState(""); // State for sorting field
	const [sortOrder, setSortOrder] = useState(""); // State for sorting order
	const [dateRange, setDateRange] = useState([]);

	const toggleModal = () => setModal(!modal);

	useEffect(() => {
		fetchLeagues();
	}, [page, sortField, sortOrder]);

	useEffect(() => {
		if (editLeague.length !== 0) {
			toggleModal();
		}
	}, [editLeague]);

	useEffect(() => {
		if (
			(byName ||
				byCountry ||
				byActive ||
				byPriority ||
				dateRange ||
				pastLeagues) &&
			page === 1
		) {
			fetchLeagues();
		}
	}, [byName, byCountry, byActive, byPriority, dateRange, pastLeagues]);

	const onActiveChange = (value) => {
		if (!value) {
			fetchLeagues();
		}
		setByActive(value);
		setPage(1);
	};

	const onShowPastLeagues = (value) => {
		if (!value) {
			fetchLeagues();
		}
		setPastLeagues(value);
		setPage(1);
	};

	const onLeagueSearch = (value) => {
		// if (!value) {
		//   fetchLeagues();
		// }
		setByName(value);
		setPage(1);
	};

	const onCountrySearch = (value) => {
		// if (!value) {
		//   fetchLeagues();
		// }
		setByCountry(value);
		setPage(1);
	};
	const onPrioritySearch = (value) => {
		// if (!value) {
		//   fetchLeagues();
		// }
		setPriority(value);
		setPage(1);
	};

	const fetchLeagues = () => {
		setLeagueLoader(true);

		const params = {
			page: page,
			limit: 10,
			name: byName,
			country: byCountry,
			active: byActive,
			priority: byPriority,
			pastLeagues: pastLeagues,
			startDate:
				dateRange.length > 0 ? dateRange[0].format("YYYY-MM-DD") : null,
			endDate: dateRange.length > 0 ? dateRange[1].format("YYYY-MM-DD") : null,
		};
		console.log(sortField, sortOrder);
		if (sortField && sortOrder) {
			params.sortBy = sortField;
			params.sortOrder = sortOrder;
		}

		API.get("dashboard/leagues", { params })
			.then((response) => {
				setLeagues(response.data.data.leagues);
				setTotal(response.data.data.length);
				setLeagueLoader(false);
			})
			.catch((err) => {
				console.log(err);
				setError(true);
				setLeagueLoader(false);
			});
	};

	const createAtractionsForLeague = (payload) => {
		setSelectedLeague(payload._id);
		setLeagueAttractionLoader(true);
		const params = {
			season: payload.seasons.length > 0 ? payload.seasons[0].year : "",
			league_id: payload.league_id,
		};

		API.post("/attractions-with-league", params)
			.then((response) => {
				const { data } = response?.data;
				setLeagueAttractionLoader(false);
				setSelectedLeague("");
				if (data?.attractions.length > 0) {
					showSuccessToast(data?.message);
				} else {
					showSuccessToast("Attractions already found in database.");
				}
			})
			.catch((err) => {
				console.log(err);
				setLeagueAttractionLoader(false);
				setSelectedLeague("");
				showErrorToast(err.message || "Something went wrong.");
			});
	};

	const createAtractionsStadiumForLeague = (payload) => {
		setSelectedLeague(payload._id);
		setLeagueAttractionStadiumLoader(true);
		const params = {
			league_id: payload.league_id,
		};

		API.post("/attractions-with-league-estimated", params)
			.then((response) => {
				const { data } = response?.data;
				setLeagueAttractionStadiumLoader(false);
				setSelectedLeague("");
				if (data) {
					showSuccessToast(data?.message);
				} else {
					showSuccessToast("Attractions already found in database.");
				}
			})
			.catch((err) => {
				console.log(err);
				setLeagueAttractionStadiumLoader(false);
				setSelectedLeague("");
				showErrorToast(err.message || "Something went wrong.");
			});
	};

	const hideModal = (event) => {
		if (!event) {
			toggleModal();
			fetchLeagues();
		} else {
			toggleModal();
		}
	};

	const handleTableChange = (pagination, filters, sorter) => {
		setPage(pagination.current);

		if (sorter && sorter.field) {
			setSortField(sorter.field);
			setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
		}
	};
	const onDateRangeChange = (dates) => {
		console.log("dates", dates);
		if (dates?.length === 2) {
			setDateRange(dates);
			setPage(1);
		} else {
			setDateRange([]);
		}
	};

	const columns = [
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			sorter: true, // Enable sorting for this column
			render: (name) => (
				<div style={{ display: "inline-flex" }}>
					<div>
						{" "}
						<img
							src={name.logo}
							alt="img"
							className="league-logo"
						/>
					</div>

					<div
						style={{
							display: "flex",
							alignItems: "center",
							marginLeft: "1em",
						}}
					>
						{" "}
						{name.name}
					</div>
				</div>
			),
		},
		{
			title: "Country",
			dataIndex: "country",
			key: "country",
			sorter: true,
		},
		{
			title: "Priority",
			dataIndex: "priority",
			key: "priority",
			sorter: true,
		},
		{
			title: "Active",
			dataIndex: "isCurrent",
			key: "isCurrent",
			sorter: true,
			render: (isCurrent) => <p>{isCurrent ? "true" : "false"}</p>,
		},
		{
			title: "Season",
			dataIndex: "season",
			key: "season",
			sorter: true,
			render: (season) => (
				<p>{`(${season.start.slice(5, 7)}/${season.start.slice(
					0,
					4
				)} to ${season.end.slice(5, 7)}/${season.end.slice(0, 4)})`}</p>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			render: (id) => (
				<div style={{ display: "flex", gap: "5px" }}>
					<Button
						type="primary"
						onClick={() => {
							const filteredLeague =
								leagues && leagues.filter((item) => item._id === id);
							setEditLeague(filteredLeague);
						}}
					>
						Edit League
					</Button>
					<Button
						type="primary"
						onClick={() => {
							if (!leagueAttractionLoader) {
								const filteredLeague =
									leagues && leagues.find((item) => item._id === id);
								createAtractionsForLeague(filteredLeague);
							}
						}}
					>
						{"Create Attraction"}
						{leagueAttractionLoader && selectedLeague === id && (
							<LoadingOutlined className="loader-button" />
						)}
					</Button>
					<Button
						type="primary"
						onClick={() => {
							if (!leagueAttractionStadiumLoader) {
								const filteredLeague =
									leagues && leagues.find((item) => item._id === id);
								createAtractionsStadiumForLeague(filteredLeague);
							}
						}}
					>
						{"Est. Attraction Stadium"}
						{leagueAttractionStadiumLoader && selectedLeague === id && (
							<LoadingOutlined className="loader-button" />
						)}
					</Button>
				</div>
			),
		},
	];

	const {
		data: countriesData,
		error: countriesError,
		loading: countriesLoading,
		makeRequest: fetchCountriesData,
	} = useApiRequest();
	useEffect(() => {
		fetchCountriesData("/countries-dashboard", "GET");
	}, []);

	const data = leagues
		? leagues.map((item, i) => ({
				key: item._id,
				name: { name: item.league.name, logo: item.league.logo },
				country: item.country.name,
				season: {
					start: item.seasons[0].start,
					end: item.seasons[0].end,
				},
				id: item._id,
				isCurrent: item.isCurrent,
				priority: item.priority,
		  }))
		: [];

	return (
		<div>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Search
					style={{ width: "20%", height: "31.5px", marginTop: "2em" }}
					placeholder="Search By League"
					className="search-box"
					onSearch={onLeagueSearch}
					onChange={(e) => {
						console.log("Empty Venue", e.target.value);
						if (!e.target.value) {
							console.log("Empty Venue IF", e.target.value);
							onLeagueSearch("");
						}
					}}
				/>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Country"
					className="search-box"
					onSearch={onCountrySearch}
					onChange={(e) => {
						console.log("Empty Venue", e.target.value);
						if (!e.target.value) {
							console.log("Empty Venue IF", e.target.value);
							onCountrySearch("");
						}
					}}
				/>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Priority"
					className="search-box"
					type="number"
					max={10}
					min={0}
					onSearch={onPrioritySearch}
					onChange={(e) => {
						console.log("Empty Venue", e.target.value);
						if (!e.target.value) {
							console.log("Empty Venue IF", e.target.value);
							onPrioritySearch("");
						}
					}}
				/>
				<Select
					defaultValue=""
					style={{ width: "15%", marginTop: "2em" }}
					onChange={onActiveChange}
				>
					<Option value="">All Leagues Active or InActive</Option>
					<Option value="true">Active</Option>
					<Option value="false">In Active</Option>
				</Select>
				<Select
					defaultValue={pastLeagues}
					style={{ width: "15%", marginTop: "2em" }}
					onChange={onShowPastLeagues}
				>
					<Option value="all">All leagues</Option>
					<Option value="past">Past</Option>
					<Option value="future">Current or Upcomming</Option>
				</Select>
				<RangePicker
					style={{ width: "200px", height: "31.5px" }}
					className="search-box"
					onChange={onDateRangeChange}
				/>
			</div>

			{leagueLoader === false ? (
				<Table
					columns={columns}
					dataSource={data}
					key="abcd"
					pagination={{ total: total, showSizeChanger: false, current: page }}
					onChange={handleTableChange} // Handle pagination and sorting changes
				/>
			) : (
				<LoadingOutlined className="loader" />
			)}

			{error && (
				<Result
					status="500"
					title="500"
					subTitle="Sorry, something went wrong."
				/>
			)}

			{modal && (
				<EditLeagueModal
					showModal={modal}
					hideModal={hideModal}
					countries={countriesData}
					league={editLeague.length !== 0 ? editLeague[0] : ""}
				/>
			)}
		</div>
	);
};

export default Leagues;
