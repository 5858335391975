/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Table, Button, Input, Tooltip } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import API from "../../helper/api";
import axios from "axios";
import "./EstimatedStadium.scss";
import EditEstimatedModal from "./EditEstimatedStadium/EditEstimatedStadium";
import { showErrorToast, showSuccessToast } from "../../helper/toaster";

const { Search } = Input;

const EstimatedStadium = () => {
	const [stadiums, setStadiums] = React.useState([]);
	const [total, setTotal] = React.useState(10);
	const [page, setPage] = React.useState(1);
	const [loading, setLoading] = React.useState(false);
	const [byName, setbyName] = React.useState("");
	const [byCity, setByCity] = React.useState("");
	const [sortField, setSortField] = React.useState("city");
	const [sortOrder, setSortOrder] = React.useState("asc");
	const [loadingButtons, setLoadingButtons] = React.useState({});

	const [selectedEstimatedStadium, setSelectedEstimatedStadium] =
		React.useState(null);
	const [modal, setModal] = useState(false);

	useEffect(() => {
		fetchEstimatedStadiumsList();
	}, [page, sortField, sortOrder, byName, byCity]);

	useEffect(() => {
		if ((byName || byCity) && page == 1) {
			console.log("filter");
			fetchEstimatedStadiumsList();
		}
	}, [byName, byCity]);

	const fetchEstimatedStadiumsList = () => {
		setLoading(true);
		API.get(`estimated-stadiums/${page}`, {
			params: {
				name: byName,
				city: byCity,
				sortBy: sortField,
				sortOrder: sortOrder,
			},
		})
			.then((res) => {
				setStadiums(res.data.data.stadiums);
				setTotal(res.data.data.total);
				setLoading(false);
			})
			.catch((error) => {
				console.log("error", error);
				setLoading(false);
			});
	};

	const updateStadium = (id, updatedFields) => {
		API.patch(`update-estimated-stadium/${id}`, updatedFields)
			.then((response) => {
				fetchEstimatedStadiumsList();
			})
			.catch((err) => {
				console.log(err);
			});
	};

	const getCountryName = async (lat, lng) => {
		const apiKey = "AIzaSyD2EUbN8GTw0joJ629a5RiZlauA7S65-8M";
		const url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${apiKey}`;
		try {
			const response = await axios.get(url);
			const results = response.data.results;

			if (results && results.length > 0) {
				const addressComponents = results[0].address_components;
				const countryComponent = addressComponents.find((component) =>
					component.types.includes("country")
				);

				if (countryComponent) {
					return countryComponent.long_name;
				} else {
					return null;
				}
			} else {
				return null;
			}
		} catch (error) {
			console.error("Error fetching geocode data", error);
			return null;
		}
	};

	const handleCreateAttraction = async (stadiumId) => {
		const filteredStadium = stadiums.find(
			(stadium) => stadium._id === stadiumId
		);
		let countryName = "";

		if (!filteredStadium?.country) {
			countryName = await getCountryName(
				filteredStadium.coordinates.lat,
				filteredStadium.coordinates.lng
			);
		}

		setLoadingButtons((prevState) => ({
			...prevState,
			[stadiumId]: true,
		}));

		const data = [
			{
				name: filteredStadium.name,
				address: filteredStadium.city,
				coordinates: [
					filteredStadium.coordinates.lng,
					filteredStadium.coordinates.lat,
				],
				venueCountry: filteredStadium?.country || countryName,
				venueId: filteredStadium.id,
				city: filteredStadium.city,
			},
		];
		try {
			const res = await API.post(`attractions-by-location-chat`, { data });
			showSuccessToast(res.data.data.message);
		} catch (err) {
			showErrorToast(res.data.error);
		} finally {
			setLoadingButtons((prevState) => ({
				...prevState,
				[stadiumId]: false,
			}));
		}
	};

	const onNameSearch = (value) => {
		setbyName(value);
		setPage(1);
	};

	const onCitySearch = (value) => {
		setByCity(value);
		setPage(1);
	};

	const handleTableChange = (pagination, filters, sorter) => {
		setPage(pagination.current);
		if (sorter && sorter.field) {
			setSortField(sorter.field);
			setSortOrder(sorter.order === "ascend" ? "asc" : "desc");
		}
	};
	const columns = [
		{
			title: "Estimated Stadiums Name",
			dataIndex: "name",
			key: "name",
			sorter: true,
			render: (obj) => {
				return (
					<Tooltip title="Show Location">
						<a
							href={`http://www.google.com/maps/place/${obj.lat},${obj.lng}`}
							target="_blank"
						>
							{obj.stadium}
						</a>
					</Tooltip>
				);
			},
		},
		{
			title: "City",
			dataIndex: "city",
			key: "city",
			sorter: true,
		},
		{
			title: "Latitude",
			dataIndex: "lat",
			key: "lat",
		},
		{
			title: "Longitude",
			dataIndex: "lng",
			key: "lng",
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			render: (id, record) => (
				<Button
					type="primary"
					onClick={() => {
						setSelectedEstimatedStadium(record);
						setModal(true);
					}}
				>
					Update Stadium
				</Button>
			),
		},
		{
			title: "",
			dataIndex: "id",
			key: "id",
			render: (id) => (
				<Button
					type="primary"
					onClick={() => handleCreateAttraction(id)}
				>
					Create attraction
					{loadingButtons[id] && <LoadingOutlined className="loader-button" />}
				</Button>
			),
		},
	];

	const data =
		stadiums &&
		stadiums.map((item) => {
			return {
				key: item._id,
				id: item._id,
				venueId: item.id,
				name: {
					stadium: item.name,
				},
				lat: item?.coordinates?.lat,
				lng: item?.coordinates?.lng,
				detail: item._id,
				city: item.city,
			};
		});

	return (
		<div>
			<div
				style={{
					width: "100%",
					display: "flex",
					justifyContent: "space-between",
				}}
			>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By Name"
					className="search-box"
					onSearch={onNameSearch}
					onChange={(e) => {
						console.log("Empty name", e.target.value);
						if (!e.target.value) {
							console.log("Empty Name IF", e.target.value);
							onNameSearch("");
						}
					}}
				/>
				<Search
					style={{ width: "200px", height: "31.5px" }}
					placeholder="Search By City"
					className="search-box"
					onSearch={onCitySearch}
					onChange={(e) => {
						console.log("Empty Name", e.target.value);
						if (!e.target.value) {
							console.log("Empty Name IF", e.target.value);
							onCitySearch("");
						}
					}}
				/>
			</div>
			{loading === false ? (
				<Table
					className="stadiums-table"
					columns={columns}
					dataSource={data}
					pagination={{
						pageSize: "20",
						total: total,
						// onChange: (page) => {
						//   setPage(page);
						// },
						current: page,
						showSizeChanger: false,
					}}
					onChange={handleTableChange}
				/>
			) : (
				<LoadingOutlined className="loader" />
			)}

			{modal && (
				<EditEstimatedModal
					selectedEstimatedStadium={selectedEstimatedStadium}
					onClose={() => setModal(false)}
					onSave={(updatedFields) => {
						// Handle saving updated fields
						updateStadium(selectedEstimatedStadium.id, updatedFields);
						setModal(false); // Close modal after save
					}}
				/>
			)}
		</div>
	);
};

export default EstimatedStadium;
